._1Sal9- {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: red;
}
._2Vy8Lv {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(https://app-prod.mozio.com/20250331113321-e01c6ae/eac4ef870fc97082a2aaa487224edce5.png) no-repeat center;
  background-size: cover
}
._2Vy8Lv:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #006890;
    opacity: 0.8;
  }
._2kC14s {
  position: relative;
  width: 100%;
  height: auto;
}
._2R69LY {
  width: 100%;
  text-align: center;
  padding: 36px 20px;
  font-size: 32px;
  color: #FFFFFF;
  background-color: #006890;
}
.wzNdQI {
  padding: 40px;
  text-align: center;
  font-size: 24px;
  font-weight: 100;
  max-width: 510px;
  color: #FFFFFF;
  margin: 0 auto;
}
.wzNdQI a {
    color: #FFFFFF;
    text-decoration: underline;
  }
._3fFGtm {
  margin-top: 40px;
}
._3HXwA3 {
  margin-bottom: 20px;
}
.fF7KjZ {
  position: relative;
  padding: 10px 80px;
  margin-top: 40px;
}
.e1FIFa {
  position: absolute;
  left: 20px;
}
/* .mobile {
  .header {
    padding: 20px;
  }
}*/

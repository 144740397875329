@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  font-style: normal;
  src: url(https://app-prod.mozio.com/20250331113321-e01c6ae/7c0d193e49065931143e49bf3495c9dd.woff2) format('woff2'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/5ea282d0de8b5303d385e0b279ff6301.woff) format('woff'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/9e353f65739cc41a37bed272850cf92e.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  font-style: normal;
  src: url(https://app-prod.mozio.com/20250331113321-e01c6ae/37f2c5eee8fb4d6ea57245ff7f827f17.woff2) format('woff2'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/e939257425132df014d1e3388ce81e81.woff) format('woff'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/f8105661cf5923464f0db8290746d2f9.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  font-style: normal;
  src: url(https://app-prod.mozio.com/20250331113321-e01c6ae/54f8078b0d439e2d081f23c5d04c59b9.woff2) format('woff2'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/ceab07e109eab22c1c8752cd62e698ee.woff) format('woff'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/aa9897f9fa37c84d7b9d3d05a8a6bc07.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  font-style: normal;
  src: url(https://app-prod.mozio.com/20250331113321-e01c6ae/771c3ec9a0c6fc0c6472ea1b23833f82.woff2) format('woff2'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/af92576411d6b5400e8b21cff837e0ee.woff) format('woff'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/69258532ce99ef9abf8220e0276fff04.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://app-prod.mozio.com/20250331113321-e01c6ae/178d7a4dacfb885abee8e2d905b08524.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/81025269949a562d06d5e316f733b140.woff2) format('woff2'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/e2f35f2d8bb12d4d3407ebe3683adaa1.woff) format('woff'),
       url(https://app-prod.mozio.com/20250331113321-e01c6ae/9ac46d2eb22f2f00090a73ed943b9522.ttf) format('truetype');
}
/**
 * keyframe animations
 */
/* train */
@-webkit-keyframes _1k62tn {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1295px 0;
  }
}
@keyframes _1k62tn {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1295px 0;
  }
}
@-webkit-keyframes _3f6cSh {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -647.5px 0;
  }
}
@keyframes _3f6cSh {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -647.5px 0;
  }
}
/* bus */
@-webkit-keyframes fRVOa7 {
  from {
    background-position: 0 -400px;
  }
  to {
    background-position: -1295px -400px;
  }
}
@keyframes fRVOa7 {
  from {
    background-position: 0 -400px;
  }
  to {
    background-position: -1295px -400px;
  }
}
@-webkit-keyframes _3Lxhbp {
  from {
    background-position: 0 -200px;
  }
  to {
    background-position: -647.5px -200px;
  }
}
@keyframes _3Lxhbp {
  from {
    background-position: 0 -200px;
  }
  to {
    background-position: -647.5px -200px;
  }
}
/* shuttle */
@-webkit-keyframes _1rkNQq {
  from {
    background-position: 0 -800px;
  }
  to {
    background-position: -1295px -800px;
  }
}
@keyframes _1rkNQq {
  from {
    background-position: 0 -800px;
  }
  to {
    background-position: -1295px -800px;
  }
}
@-webkit-keyframes -oYJF5 {
  from {
    background-position: 0 -400px;
  }
  to {
    background-position: -647.5px -400px;
  }
}
@keyframes -oYJF5 {
  from {
    background-position: 0 -400px;
  }
  to {
    background-position: -647.5px -400px;
  }
}
/* local */
@-webkit-keyframes MFafO4 {
  from {
    background-position: 0 -1200px;
  }
  to {
    background-position: -1295px -1200px;
  }
}
@keyframes MFafO4 {
  from {
    background-position: 0 -1200px;
  }
  to {
    background-position: -1295px -1200px;
  }
}
@-webkit-keyframes _3Z9OID {
  from {
    background-position: 0 -600px;
  }
  to {
    background-position: -647.5px -600px;
  }
}
@keyframes _3Z9OID {
  from {
    background-position: 0 -600px;
  }
  to {
    background-position: -647.5px -600px;
  }
}
/* taxi */
@-webkit-keyframes _2_j5DE {
  from {
    background-position: 0 -1600px;
  }
  to {
    background-position: -1295px -1600px;
  }
}
@keyframes _2_j5DE {
  from {
    background-position: 0 -1600px;
  }
  to {
    background-position: -1295px -1600px;
  }
}
@-webkit-keyframes WlEkX5 {
  from {
    background-position: 0 -800px;
  }
  to {
    background-position: -647.5px -800px;
  }
}
@keyframes WlEkX5 {
  from {
    background-position: 0 -800px;
  }
  to {
    background-position: -647.5px -800px;
  }
}
/* sedan */
@-webkit-keyframes _3MtKhh {
  from {
    background-position: 0 -2000px;
  }
  to {
    background-position: -1295px -2000px;
  }
}
@keyframes _3MtKhh {
  from {
    background-position: 0 -2000px;
  }
  to {
    background-position: -1295px -2000px;
  }
}
@-webkit-keyframes _2jr9g0 {
  from {
    background-position: 0 -1000px;
  }
  to {
    background-position: -647.5px -1000px;
  }
}
@keyframes _2jr9g0 {
  from {
    background-position: 0 -1000px;
  }
  to {
    background-position: -647.5px -1000px;
  }
}
/* suv */
@-webkit-keyframes _2EDhgC {
  from {
    background-position: 0 -2400px;
  }
  to {
    background-position: -1295px -2400px;
  }
}
@keyframes _2EDhgC {
  from {
    background-position: 0 -2400px;
  }
  to {
    background-position: -1295px -2400px;
  }
}
@-webkit-keyframes _2GBP0t {
  from {
    background-position: 0 -1200px;
  }
  to {
    background-position: -647.5px -1200px;
  }
}
@keyframes _2GBP0t {
  from {
    background-position: 0 -1200px;
  }
  to {
    background-position: -647.5px -1200px;
  }
}
/* limo */
@-webkit-keyframes vgxpKn {
  from {
    background-position: 0 -2800px;
  }
  to {
    background-position: -1295px -2800px;
  }
}
@keyframes vgxpKn {
  from {
    background-position: 0 -2800px;
  }
  to {
    background-position: -1295px -2800px;
  }
}
@-webkit-keyframes V7uFDx {
  from {
    background-position: 0 -1400px;
  }
  to {
    background-position: -647.5px -1400px;
  }
}
@keyframes V7uFDx {
  from {
    background-position: 0 -1400px;
  }
  to {
    background-position: -647.5px -1400px;
  }
}
._2qdlI5 {
  position: absolute;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}
._1H79ts {
  opacity: 1;
  visibility: visible;
}
._1pBKgx {
  overflow: hidden;
  height: 400px;
  width: 400px;
  margin: 0 auto;
  background: #0D84EB;
  border-radius: 50%;
}
._1siDwP {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(https://app-prod.mozio.com/20250331113321-e01c6ae/6fcf9a9057cfb056b53498f4825b0197.jpg) repeat-x;
  background-size: 1295px 3200px;
}
._2acjvS {
  -webkit-animation: _1k62tn 5s linear infinite;
          animation: _1k62tn 5s linear infinite;
}
._1gp2wd {
  -webkit-animation: fRVOa7 5s linear infinite;
          animation: fRVOa7 5s linear infinite;
}
._1Wb_ih {
  -webkit-animation: _3MtKhh 5s linear infinite;
          animation: _3MtKhh 5s linear infinite;
}
._1jTW11 {
  -webkit-animation: MFafO4 5s linear infinite;
          animation: MFafO4 5s linear infinite;
}
._838PeD {
  -webkit-animation: vgxpKn 5s linear infinite;
          animation: vgxpKn 5s linear infinite;
}
._1YzKYc {
  -webkit-animation: _2_j5DE 5s linear infinite;
          animation: _2_j5DE 5s linear infinite;
}
._34mAWL {
  -webkit-animation: _2EDhgC 5s linear infinite;
          animation: _2EDhgC 5s linear infinite;
}
._3Djoxj {
  -webkit-animation: _1rkNQq 5s linear infinite;
          animation: _1rkNQq 5s linear infinite;
}
._2JGcUd {
  background: url(https://app-prod.mozio.com/20250331113321-e01c6ae/1a7eff74c707b6011984f6b5cc0d2a10.png) no-repeat;
  background-size: 1061px 602.5px;
}
._2acjvS ._2JGcUd {
  position: absolute;
  bottom: 51px;
  left: -700px;
  height: 150px;
  min-width: 978px;
  background-position: 0 0;
}
._1gp2wd ._2JGcUd {
  position: absolute;
  bottom: 44px;
  left: -250px;
  height: 177px;
  min-width: 557px;
  background-position: 0 -157px;
}
._1Wb_ih ._2JGcUd {
  position: absolute;
  bottom: 10px;
  left: -40px;
  height: 140px;
  width: 294px;
  background-position: 0 -336px;
}
._1jTW11 ._2JGcUd {
  position: absolute;
  bottom: 14px;
  left: -50px;
  height: 117px;
  width: 305px;
  background-position: -678px -357px;
}
._838PeD ._2JGcUd {
  position: absolute;
  bottom: 8px;
  left: -50px;
  height: 124px;
  width: 311px;
  background-position: 0 -477px;
}
._1YzKYc ._2JGcUd {
  position: absolute;
  bottom: 23px;
  left: -30px;
  height: 109px;
  width: 307px;
  background-position: -318px -493px;
}
._34mAWL ._2JGcUd {
  position: absolute;
  bottom: 15px;
  left: -70px;
  height: 131px;
  width: 367px;
  background-position: -297px -345px;
}
._3Djoxj ._2JGcUd {
  position: absolute;
  bottom: 16px;
  left: -150px;
  height: 131px;
  min-width: 491px;
  background-position: -564px -200px;
}
/**
 * @media
 */
@media all and (max-width: 960px) {
  ._1pBKgx {
    width: 200px;
    height: 200px;
  }
  ._1siDwP {
    background-size: 647.5px 1600px;
  }
  ._2acjvS {
    -webkit-animation: _3f6cSh 5s linear infinite;
            animation: _3f6cSh 5s linear infinite;
  }
  ._1gp2wd {
    -webkit-animation: _3Lxhbp 5s linear infinite;
            animation: _3Lxhbp 5s linear infinite;
  }
  ._1Wb_ih {
    -webkit-animation: _2jr9g0 5s linear infinite;
            animation: _2jr9g0 5s linear infinite;
  }
  ._1jTW11 {
    -webkit-animation: _3Z9OID 5s linear infinite;
            animation: _3Z9OID 5s linear infinite;
  }
  ._838PeD {
    -webkit-animation: V7uFDx 5s linear infinite;
            animation: V7uFDx 5s linear infinite;
  }
  ._1YzKYc {
    -webkit-animation: WlEkX5 5s linear infinite;
            animation: WlEkX5 5s linear infinite;
  }
  ._34mAWL {
    -webkit-animation: _2GBP0t 5s linear infinite;
            animation: _2GBP0t 5s linear infinite;
  }
  ._3Djoxj {
    -webkit-animation: -oYJF5 5s linear infinite;
            animation: -oYJF5 5s linear infinite;
  }
  ._2JGcUd {
    background-size: 530.5px 301px;
  }
  ._2acjvS ._2JGcUd {
    height: 75px;
    width: 489px;
    bottom: 25px;
    left: -350px;
  }
  ._1gp2wd ._2JGcUd {
    background-position: 0 -78.5px;
    height: 88.5px;
    min-width: 278.5px;
    bottom: 22px;
    left: -125px;
  }
  ._1Wb_ih ._2JGcUd {
    background-position: 0 -168px;
    height: 70px;
    width: 147px;
    bottom: 5px;
    left: -20px;
  }
  ._1jTW11 ._2JGcUd {
    background-position: -339px -178.5px;
    height: 58.5px;
    width: 152.5px;
    bottom: 7px;
    left: -25px;
  }
  ._838PeD ._2JGcUd {
    background-position: 0 -238.5px;
    height: 62px;
    width: 155.5px;
    bottom: 4px;
    left: -25px;
  }
  ._1YzKYc ._2JGcUd {
    background-position: -159px -246.5px;
    height: 54.5px;
    width: 153.5px;
    bottom: 11.5px;
    left: -15px;
  }
  ._34mAWL ._2JGcUd {
    background-position: -148.5px -172.5px;
    height: 65.5px;
    width: 183.5px;
    bottom: 7px;
    left: -35px;
  }
  ._3Djoxj ._2JGcUd {
    background-position: -282px -100px;
    height: 65.5px;
    width: 245.5px;
    bottom: 8px;
    left: -75px;
  }
}

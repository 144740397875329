.yWjL95 {
  position: relative;
  float: left;
  width: 12.5%;
  cursor: pointer;
  text-align: center;
}

  .yWjL95 p {
    color: #0D84EB;
    white-space: nowrap;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 20px;
    transition: all 200ms ease-in-out;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
.yWjL95:after {
    position: absolute;
    bottom: 0;
    content: '';
    opacity: 0.4;
    background: #0D84EB;
    height: 6px;
    width: 100%;
  }
.yWjL95:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 80px;
  }
.yWjL95:last-child:after {
      display: none;
    }
p._13NVjg {
  margin: 20px 0 0;
  -webkit-transform: translate(0, 38px);
          transform: translate(0, 38px);
}
.yWjL95:hover .sCM7V1 p,
._2E6fSK .sCM7V1 p {
  -webkit-transform: translate(0, -10px);
          transform: translate(0, -10px);
  color: #2F6FA8;
}
.yWjL95:hover .sCM7V1 p._13NVjg,
._2E6fSK .sCM7V1 p._13NVjg {
  -webkit-transform: translate(0, 48px);
          transform: translate(0, 48px);
}
.yWjL95:hover ._2uQCp8,
._2E6fSK ._2uQCp8 {
  width: 38px;
  height: 38px;
  margin: 0 0 -16px -20px;
}
.yWjL95:hover ._2uQCp8 div,
._2E6fSK ._2uQCp8 div {
  transition: 0.3s ease all;
  opacity: 1;
}
._2uQCp8 {
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  background-color: #0D84EB;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 200ms ease-in-out;
  width: 10px;
  height: 10px;
  margin: 0 0 -2px -5px;
  border-radius: 50%;
}
._3a1o6R {
  background: url(https://app-prod.mozio.com/20250331113321-e01c6ae/79025e6c49a46dbbd903ba2a24715cf4.png) no-repeat;
  background-size: 26px 195.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: 0.1s ease all;
  opacity: 0;
}
.YHU7-G {
  width: 27px;
  height: 20px;
}
._2AILn5 {
  width: 26px;
  height: 13px;
  background-position: 0 -30px;
}
._3CjMN8 {
  width: 26px;
  height: 14px;
  background-position: 0 -56px;
}
._m3Otc {
  width: 26px;
  height: 13px;
  background-position: 0 -82px;
}
._2YgoSs {
  width: 26px;
  height: 13px;
  background-position: 0 -109px;
}
._24FL49 {
  width: 26px;
  height: 13px;
  background-position: 0 -135px;
}
._3xDCMH {
  width: 26px;
  height: 13px;
  background-position: 0 -161px;
}
.adfDfr {
  width: 26px;
  height: 13px;
  background-position: 0 -187px;
}
